export const columns = [
	{
		title: "分类名称",
		dataIndex: "name",
		width: "33%",
	},
	{
		title: "图片",
		dataIndex: "imgpic",
		width: "33%",
		scopedSlots: { customRender: "imgpic"}
	},
	{
		title: "是否显示",
		dataIndex: "isShow",
		width: "33%",
		customRender: function (isShow) {
			switch (isShow) {
			case 1:
				return '显示'
			case 0:
				return '不显示'
			}
		}
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}
export const rules = {
	name:[{required:true,message:'请输入名称',trigger:'blur'}],
	parentId:[{required:true,message:'请选择',trigger:'change',type:'array'}],
}