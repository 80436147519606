<template>
  <div>
    <div class="cardTitle">商品分类</div>
    <div class="shop-content">
      <div class="shop-tree">
        <a-button class="add-btn" style="margin-left: 10px" @click="addCate"
          >新增商品分类</a-button
        >
        <a-tree
          v-model="checkedKeys"
          :expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent"
          :selected-keys="selectedKeys"
          :tree-data="treeData"
          @expand="onExpand"
          @select="onSelect"
          :replaceFields="{
            children: 'shopCategoryVoList',
            title: 'name',
            key: 'id',
          }"
        >
        </a-tree>
      </div>
      <a-divider type="vertical" style="height: 100vh"></a-divider>
      <div class="table">
        <a-table
          :columns="columns"
          :data-source="tableData"
          :pagination="pagination"
          @change="handleTableChange"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: selectionChoosed,
          }"
          :row-key="
            (record, index) => {
              return record.id;
            }
          "
        >
          <span slot="action" slot-scope="text, row">
            <a-space>
              <a @click="edit(row)">修改</a>
              <a @click="show(row.id)" v-if="row.isShow === 0">显示</a>
              <a @click="show(row.id)" v-else-if="row.isShow === 1">隐藏</a>
              <a class="ant-dropdown-link" @click="del([row.id])">删除</a>
            </a-space>
          </span>
          <span slot="imgpic" slot-scope="text, row">
            <img
              v-if="row.imgUrls.length > 0"
              :src="$ImgUrl(row.imgUrls[0].url)"
              class="table-img"
              alt=""
            />
            <span v-else>无图片</span>
          </span>
        </a-table>
        <div class="action">
          <a-dropdown :disabled="!hasSelected">
            <a-menu slot="overlay" @click="handleMenuClick">
              <a-menu-item key="del"> 批量删除 </a-menu-item>
            </a-menu>
            <a-button> 批量操作 <a-icon type="down" /> </a-button>
          </a-dropdown>
          <span style="margin-left: 8px">
            <template v-if="hasSelected">
              {{ `已选择 ${selectedRowKeys.length} 条` }}
            </template>
          </span>
        </div>
      </div>
    </div>
    <a-drawer
      width="30%"
      :title="drawer.title"
      placement="right"
      :closable="false"
      :visible="drawer.show"
      @close="onClose"
    >
      <div class="main">
        <a-form-model ref="ruleForm" :model="form" :rules="rules">
          <a-form-model-item prop="name" label="分类名称">
            <a-input
              placeholder="请输入分类名称"
              v-model="form.name"
              style="width: 80%"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item
            prop="name"
            label="上级分类"
            v-if="drawer.title === '新增分类'"
          >
            <a-cascader
              style="width: 80%"
              :field-names="{
                label: 'name',
                value: 'id',
                children: 'shopCategoryVoList',
              }"
              :options="treeData"
              placeholder="请选择"
              change-on-select
              @change="onChange"
              v-model="cascVal"
            />
          </a-form-model-item>
          <a-form-model-item label="分类图片">
            <commonUpload
              :fileList="fileList"
              @handleChange="handleChange"
            ></commonUpload>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="onClose">
          关闭
        </a-button>
        <a-button type="primary" @click="onSubmit"> 提交 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import {
	cateList,
	cateInfo,
	cateShow,
	cateInsert,
	cateUpdate,
	cateDel,
} from "@/api/shop/goods/cate.js";
import { columns, pagination, rules } from "./depend/config";
export default {
	data() {
		return {
			columns,
			pagination,
			rules,
			// 选择的index
			selectedRowKeys: [],
			tableData: [],
			fileList: [],
			drawer: {
				show: false,
				title: "新增分类",
			},
			form: {
				categoryId: undefined, //修改用 分类主键id
				parentId: undefined,
				name: undefined,
				imgList: [],
			},
			expandedKeys: [],
			autoExpandParent: true,
			checkedKeys: [],
			selectedKeys: [],
			treeData: [
				{
					id: 0,
					name: "所有分类",
					shopCategoryVoList: [],
				},
			],
			cascVal: [],
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		async getData() {
			let res = await cateList();
			this.treeData[0].shopCategoryVoList = res.data;
			this.onSelect(this.selectedKeys);
		},
		addCate() {
			this.drawer.show = true;
			this.drawer.title = "新增分类";
		},
		del(ids) {
			this.$confirm({
				title: "是否删除",
				// okText:'删除',
				// cancelText:'取消',
				icon: "delete",
				onOk: async () => {
					let res = await cateDel({ ids: ids });
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.getData();
					} else {
						this.$message.error(res.msg);
					}
				},
			});
		},
		onSubmit() {
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					if (this.drawer.title === "新增分类") {
						let res = await cateInsert(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.getData();
							this.onClose();
						} else {
							this.$message.error(res.msg);
						}
					} else if (this.drawer.title === "修改分类") {
						let res = await cateUpdate(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.getData();
							this.onClose();
						} else {
							this.$message.error(res.msg);
						}
					}
				}
			});
		},
		onClose() {
			this.drawer.show = false;
			(this.form = {
				categoryId: undefined, //修改用 分类主键id
				parentId: undefined,
				name: undefined,
				imgList: [],
			}),
			(this.cascVal = []);
			this.fileList = [];
		},
		async show(id) {
			let res = await cateShow({ shopCategoryId: id });
			if (res.code === 200) {
				this.$message.success(res.msg);
				this.onSelect(this.selectedKeys);
			} else {
				this.$message.error(res.msg);
			}
		},
		onChange(value) {
			this.form.parentId = value[value.length - 1];
		},
		edit(data) {
			this.drawer.show = true;
			this.drawer.title = "修改分类";
			this.form.name = data.name;
			this.form.categoryId = data.id;
			// this.fileList.push
			if (data.imgUrls.length > 0) {
				this.form.imgList.push(data.imgUrls[0].url);
				const pic = [];
				for (let item of data.imgUrls) {
					let obj = {
						name: item.url.split("_")[0] + "." + item.url.split(".")[1],
						url: this.$ImgUrl(item.url),
						uid: item.url.split("_")[1],
						status: "done",
						thumbUrl: this.$ImgUrl(item.url),
					};
					pic.push(obj);
				}
				this.fileList = pic;
			} else {
				this.form.imgList = [];
			}
		},
		handleChange(data) {
			this.fileList = data;
			this.form.imgList = [];
			data.forEach(ele => {
				if(ele.status == 'done') {
					this.form.imgList.push(ele.response.data)
				}
			})
			// if (data[0].status === "done") {
			// 	console.log("-------done-------");
			// 	this.form.imgList.push(data[0].response.data);
			// }
		},
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
		// 树操作
		onExpand(expandedKeys) {
			this.expandedKeys = expandedKeys;
			this.autoExpandParent = false;
		},
		handleMenuClick(data) {
			if (data.key === "del") {
				this.del(this.selectedRowKeys);
			}
		},
		async onSelect(selectedKeys) {
			this.selectedKeys = selectedKeys;
			let res = await cateInfo({
				parentId: this.selectedKeys[0],
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total;
		},
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>

<style lang="less" scoped>
.shop-content {
	padding: 13px 13px 13px 0px;
	display: flex;
	.shop-tree {
		padding: 0px 60px 0px 0px
	}
}
.table-img {
  width: 40px;
  height: 40px;
}
.flexbox {
  display: flex;
  justify-content: space-around;
}
.tree {
  padding-left: 30px;
  min-width: 200px;
}
.table {
  width: 80%;
}
</style>
